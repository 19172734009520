<template>
    <div id="page-lap-pelayanan-harian">
      <b-container fluid>
        <b-row>
          <b-col cols="12" sm="12" md="12" lg="12" xl="12">
            <b-card>
              <b-row>
                <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                  <b-form-group label-cols-md="4">
                    <template v-slot:label>
                      Dari
                    </template>
                    <date-picker
                      style="width: 100%"
                      id="tanggal_awal"
                      format="DD-MM-YYYY"
                      v-model="data_search.tanggal_awal"
                    ></date-picker>
                  </b-form-group>
                  <b-form-group label-cols-md="4">
                    <template v-slot:label>
                      Sampai
                    </template>
                    <date-picker
                      style="width: 100%"
                      id="tanggal_akhir"
                      format="DD-MM-YYYY"
                      v-model="data_search.tanggal_akhir"
                    ></date-picker>
                  </b-form-group>
                </b-col>
                <!-- <b-col cols="12" sm="12" md="12" lg="6" xl="6">
                  <div style="width:100%;display:flex">
                    <div style="width:50%;height:30px;display:flex;justify-content:flex-start;align-items:center">
                      <h6 style="margin:0;font-weight:bold">Puskesmas</h6>
                    </div>
                    <div style="width:50%;height:30px;display:flex;justify-content:flex-end;align-items:center">
                      <b-button variant="outline-primary" size="sm">
                        <b-form-checkbox
                          id="check_puskesmas"
                          name="check_puskesmas"
                          v-model="checkedPuskesmas"
                          @change="toggleSelectPuskesmas()"
                        >
                          <span class="pt-1">Pilih Semua</span>
                        </b-form-checkbox>
                      </b-button>
                    </div>
                  </div>
                  <hr>
                  <b-row style="padding-left:7.5px;margin-top:15px;">
                    <b-col v-for="(item, idx) in listPuskesmas" :key="idx" cols="12" sm="12" md="12" lg="6" xl="6">
                        <b-form-checkbox
                          v-model="item.checked"
                        >
                          {{ item.nama_puskesmas }}
                        </b-form-checkbox>
                    </b-col>
                  </b-row>
                </b-col> -->
                <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                  <div style="width:100%;display:flex">
                    <div style="width:50%;height:30px;display:flex;justify-content:flex-start;align-items:center">
                      <h6 style="margin:0;font-weight:bold">Poli</h6>
                    </div>
                    <div style="width:50%;height:30px;display:flex;justify-content:flex-end;align-items:center">
                      <b-button variant="outline-primary" size="sm">
                        <b-form-checkbox
                          id="check_poli"
                          name="check_poli"
                          v-model="checkedPoli"
                          @change="toggleSelectPoli()"
                        >
                          <span class="pt-1">Pilih Semua</span>
                        </b-form-checkbox>
                      </b-button>
                    </div>
                  </div>
                  <hr>
                  <b-row style="padding-left:7.5px;margin-top:15px;">
                    <b-col v-for="(item, idx) in listPoli" :key="idx" cols="12" sm="12" md="12" lg="6" xl="6">
                        <b-form-checkbox
                          v-model="item.checked"
                        >
                          {{ item.nama_poli }}
                        </b-form-checkbox>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-row class="mt-3">
                <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                  <div style="display:flex;justify-content:flex-start">
                    <b-button @click="getSearch()" variant="primary" class="mr-2">Tampilkan</b-button>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                  <hr>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                  <h4 style="text-align:center"><strong>LAPORAN HARIAN - KINERJA PELAYANAN RUANG</strong></h4>
                  <h5 style="text-align:center; margin-top:5px"><strong>Total Kunjungan : {{ totalRows }}</strong></h5>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" md="12" lg="12">
                  <b-table-simple responsive class="custom-table">
                    <b-thead>
                      <b-tr class="text-center">
                        <b-th rowspan="3" style="min-width: 30px;">No</b-th>
                        <b-th rowspan="3" style="min-width: 130px;">Tanggal</b-th>
                        <b-th colspan="4" style="min-width: 100px;">BPJS Kesehatan	</b-th>
                        <b-th colspan="4" style="min-width: 100px;">UMUM</b-th>
                      </b-tr>
                      <b-tr class="text-center">
                        <b-th colspan="2" style="min-width: 50px;">Baru</b-th>
                        <b-th colspan="2" style="min-width: 50px;">Lama</b-th>
                        <b-th colspan="2" style="min-width: 50px;">Baru</b-th>
                        <b-th colspan="2" style="min-width: 50px;">Lama</b-th>
                        <!-- <b-th rowspan="2" style="min-width: 50px;">L</b-th> -->
                      </b-tr>
                      <b-tr class="text-center">
                        <b-th style="min-width: 50px;">L</b-th>
                        <b-th style="min-width: 50px;">P</b-th>
                        <b-th style="min-width: 50px;">L</b-th>
                        <b-th style="min-width: 50px;">P</b-th>
                        <b-th style="min-width: 50px;">L</b-th>
                        <b-th style="min-width: 50px;">P</b-th>
                        <b-th style="min-width: 50px;">L</b-th>
                        <b-th style="min-width: 50px;">P</b-th>
                      </b-tr>
                    </b-thead>
                    <b-tbody>
                      <b-tr v-for="(item, idx) in items" :key="idx">
                        <b-td class="text-center">{{ idx + 1 }}</b-td>
                        <b-td>{{ $moment(item.tanggal).format('LL') }}</b-td>
                        <b-td class="text-center">{{ item.data_asuransi.bpjs_kesehatan.baru.L }}</b-td>
                        <b-td class="text-center">{{ item.data_asuransi.bpjs_kesehatan.baru.P }}</b-td>
                        <b-td class="text-center">{{ item.data_asuransi.bpjs_kesehatan.lama.L }}</b-td>
                        <b-td class="text-center">{{ item.data_asuransi.bpjs_kesehatan.lama.P }}</b-td>
                        <b-td class="text-center">{{ item.data_asuransi.umum.baru.L }}</b-td>
                        <b-td class="text-center">{{ item.data_asuransi.umum.baru.P }}</b-td>
                        <b-td class="text-center">{{ item.data_asuransi.umum.lama.L }}</b-td>
                        <b-td class="text-center">{{ item.data_asuransi.umum.lama.P }}</b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
        <!-- {{items}} -->
      </b-container>
    </div>
</template>
  
<script>
export default {
  name: "kunjungan_pasien",
  data() {
    return {
      showing: false,
      variant: "success",
      msg: "",
      value: null,
      kunjungan_5: false,
      total: null,
      data_search: {
        tanggal_awal: null,
        tanggal_akhir: null,
        puskesmas: [],
        poli: [],
      },
      totalRows: 0,
      items: [],
      checkedPuskesmas: false,
      listPuskesmas: [],
      checkedPoli: false,
      listPoli: [],
      filter: null,
      tableBusy: false,
      disabled_field: false,
    };
  },
  computed: {
  },
  activated(){
    this.getData()
    this.reset()
  },
  methods: {
    async getData(){
      const vm = this
      vm.tableBusy = false
      try {
        const listPoli = await vm.$axios.post('/ms_poli/list')
        vm.listPoli = listPoli.data.status == 200 ? listPoli.data.data : []
        const listPuskesmas = await vm.$axios.post('/puskesmas/list')
        vm.listPuskesmas = listPuskesmas.data.status == 200 ? listPuskesmas.data.data : []
      } catch (error) {
        console.log(error)
      } finally {
        vm.tableBusy = false
      }
    },
    async getSearch(){
      const vm = this
      vm.tableBusy = false
      try {
        // //puskesmas
        // const bulk_puskesmas_id = []
        // for (let i = 0; i < vm.listPuskesmas.length; i++) {
        //   const puskesmas = vm.listPuskesmas[i];
        //   if(puskesmas.checked){
        //     bulk_puskesmas_id.push(puskesmas.id)
        //   }
        // }
        //poli
        const bulk_ms_poli_id = []
        for (let i = 0; i < vm.listPoli.length; i++) {
          const poli = vm.listPoli[i];
          if(poli.checked){
            bulk_ms_poli_id.push(poli.ms_poli_id)
          }
        }
        const res = await vm.$axios.post('/pendaftaran/laporan_pelayanan_ruangan', {
          tanggal_awal: vm.$moment(vm.data_search.tanggal_awal).format('YYYY-MM-DD'),
          tanggal_akhir: vm.$moment(vm.data_search.tanggal_akhir).format('YYYY-MM-DD'),
          jenis_kelamin: vm.data_search.jenis_kelamin ? vm.data_search.jenis_kelamin.value : null,
          pasien: vm.data_search.pasien ? vm.data_search.pasien.value : null,
          bulk_puskesmas_id: [vm.$store.state.puskesmas_id],
          bulk_ms_poli_id,
        })
        console.log('pendaftaran/laporan_pelayanan_ruangan', res)
        vm.items = []
        if(res.data.status == 200){
          vm.totalRows = res.data.data.length
          vm.items = res.data.data
          console.log('laporan_pendapatan_tindakan', vm.items)
        }
      } catch (error) {
        console.log(error)
      } finally {
        vm.tableBusy = false
      }
    },
    async toggleSelectPuskesmas () {
      const vm = this
      vm.listPuskesmas.forEach(function (data) {
        data.checked = vm.checkedPuskesmas;
      });
      vm.$forceUpdate()
    },
    async toggleSelectPoli () {
      const vm = this
      vm.listPoli.forEach(function (data) {
        data.checked = vm.checkedPoli;
      });
      vm.$forceUpdate()
    },
    async reset(){
      const vm = this
      vm.tableBusy = false
      vm.data_search = {
        tanggal_awal:  new Date(vm.$moment(vm.$store.state.tanggal_sesi).subtract(1, 'months')),
        tanggal_akhir: new Date(vm.$store.state.tanggal_sesi),
        puskesmas: [],
        poli: [],
      }
      vm.getSearch()
    },
    triggerAlert(event) {
      let vm = this;
      vm.$store.commit("set_alert", event);
    },
  },
};
</script>
<style>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* #page-lap-pelayanan-harian .table-lap-pelayanan-harian th{
  background-color: #D52F65 !important ;
  color: #fff;
  font-weight: 600;
}

#page-lap-pelayanan-harian .table-lap-pelayanan-harian th, td{
  border: 1px solid #F1F1F1 !important;
}

#page-lap-pelayanan-harian .table-lap-pelayanan-harian th:first-child {
  border-top-left-radius: 8px;
}

#page-lap-pelayanan-harian .table-lap-pelayanan-harian th:last-child {
  border-top-right-radius: 8px;
} */
</style>
